/*--- dashboard css --*/
@font-face {
  font-family: "Helvetica";
  src: url("/src/fonts/Helvetica-Bold.ttf") format("ttf"),
    url("/src/fonts/Helvetica.ttf") format("truetype");
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}

/* global font-size and color  */
.content p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
b,
strong,
body {
  font-family: Helvetica;
}

p {
  color: #323232;
  font-size: 16px !important;
  line-height: 24px !important;
}

a {
  font-family: Helvetica;
}

#card1 {
  font: normal normal normal 17px/21px Helvetica;
}

.col-md-12.content-widthsidebar.content-wrapper {
  /* overflow: scroll;  */
  overflow-y: auto;
  height: 100vh;
  scrollbar-width: none;
}

.content-widthsidebar {
  margin-left: 250px !important;
  padding: 20px;
  max-width: calc(100% - 250px);
}

.dashboard-footer {
  position: fixed;
  bottom: 0px;
  min-height: 90px;
  display: flex;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -7px 26px #0000001a;
  width: auto;
  left: 250px;
  padding: 0px 20px;
  right: 0px;
}

.dashboard-footer > div {
  width: 100%;
}

.dashboard-footer > div > .row {
  align-items: center;
}

body:not(.layout-fixed) .main-sidebar {
  height: 100vh;
}

/* .dashboardContent>.row {
    margin: 0px;
} */

.ftboxlinks-blue > a {
  background-color: #0f4beb;
  color: #fff;
  padding: 8px;
  text-align: center;
  border-radius: 3px;
  font-size: 15px;
  display: block;
}

.ftboxlinks-red > a {
  /* background-color: #FF2305; */
  background-color: #8b0000;
  color: #fff;
  padding: 8px;
  font-size: 13px;
  text-align: center;
  border-radius: 3px;
  display: block;
}

.ftboxlinks-blue > a:hover {
  opacity: 0.8;
}

.questions-table tbody tr {
  display: flex;
  flex-wrap: wrap;
}

.questions-table tbody tr td:first-child {
  width: 100%;
  font: normal normal bold 18px/24px Helvetica;
  letter-spacing: 0px;
  color: #323232;
}

table.table.questions-table,
.content-topheads-text {
  background: #fff;
}

.questions-table tbody tr td {
  display: flex;
  width: 20%;
}

.questions-table tbody tr td label {
  display: inline-block;
  font: normal normal 16px/24px Helvetica;
  letter-spacing: 0px;
  color: #323232;
  font-weight: normal !important;
  margin-left: 5px;
}

.questions-table tbody tr td span {
  border: none;
  background: none;
  padding: 0px;
  display: inline-block;
}

.questions-table tbody tr td span input {
  margin: 0px;
  position: relative;
}

.content-topheads-text {
  padding: 0.75rem;
  margin-bottom: 15px;
}

.row.header-titlewith-text {
  padding: 0.95rem 0px;
  margin-bottom: 15px;
}

.row.header-titlewith-text .title {
  font: normal normal bold 28px/34px Helvetica;
  letter-spacing: 0px;
  color: #212529;
}

.row.header-titlewith-text .text-right {
  font: normal normal normal 15px/28px Helvetica;
  letter-spacing: 0px;
  color: #323232;
  opacity: 1;
}

.row.header-titlewith-text .text-right span {
  color: #0f4beb;
}

.content-with-sidebar-row .content-wrapper {
  width: calc(100% - 255px);
  max-width: calc(100% - 255px);
  padding-bottom: 200px;
}

.note-textheads {
  font: normal normal bold 17px/21px Helvetica;
  letter-spacing: 0px;
  color: #000000;
  border-bottom: solid 1px #eee;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.ft-headstext,
.audio-title {
  font: normal normal normal 17px/21px Helvetica;
  letter-spacing: 0px;
  color: #323232;
  margin-bottom: 8px;
}

.audio-player,
.audio-player audio {
  height: 30px;
  display: flex;
  justify-content: center;
}

.subtext-blocksec span {
  font: normal normal normal 13px/21px Helvetica;
  letter-spacing: 0px;
  color: #323232;
  margin-bottom: 5px;
  display: inline-block;
}

.fticons {
  width: 16px;
}

.ftboxlinks-red a:hover {
  opacity: 0.8;
}

/* placeholder font size */
::placeholder {
  font-size: 14px;
}

.form-group label {
  font-weight: normal !important;
}

.form-control {
  background: #ecf1fe;
}

.css-1s2u09g-control {
  background: #ecf1fe !important;
}

.hover-txt {
  bottom: 70px;
  max-width: 400px;
  background-color: #212529;
  color: #fff;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 9999999999 !important;
  right: 0px;
  font-size: 14px;
}

.signuptext a {
  font-size: 17px;
}

.content-div {
  overflow: scroll;
  height: 100vh;
  width: 100%;
  max-width: 100%;
}

.user-reponse-submit {
  background: #0f4beb;
  color: #fff;
  font-weight: bold;
  width: fit-content;
  margin-left: 8px;
  font-size: 15px;
  border-radius: 4px;
  padding: 4px 10px;
}

/* .user-reponse-submit:hover{
    opacity: .6;
} */

.user-reponse-submit1 {
  background: #0f4beb;
  color: #fff;
  font-weight: bold;
  width: fit-content;
  margin-left: 8px;
  font-size: 15px;
  border-radius: 4px;
  padding: 4px 10px;
}

.user-reponse-submit1:hover {
  opacity: 0.6;
}

button.user-reponse-submit.btn.btn-primary.link-sty.btn.btn-primary {
  background: #0f4beb;
  color: #fff;
  font-weight: bold;
  width: fit-content;
  margin-left: 0px;
  font-size: 15px;
  border-radius: 4px;
  padding: 4px 10px;
  margin-left: 9px;
}

.downloadButton {
  /* background: #FF5733; */
  background: #8b0000;
  color: #fff;
  font-weight: bold;
  width: fit-content;
  margin-left: 0px;
  font-size: 15px;
  border-radius: 4px;
  padding: 10px 10px;
  border: #ff5733;
  /* border: '#8b0000' */
}

/* radio button css  */
input[type="radio"]:after {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ebebeb;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 1px solid #b2b2b2;
}

input[type="radio"]:checked:after {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #fff;
  content: "";
  visibility: visible;
  border: 5px solid #009b00;
}

/* end */
.col-md-7.ftboxlinks-blue,
.ftboxlinks-red a {
  font: normal normal bold 15px/28px Helvetica;
}

.questions-table tbody tr td label {
  margin-left: 8px;
}

/* loading icon css */
.loader-icon svg {
  height: 70vh;
  position: relative;
  bottom: 50% !important;
  left: 50%;
}

.col-md-7.ftboxlinks-blue,
.ftboxlinks-red a {
  font: normal normal bold 15px/28px Helvetica;
}

.content div {
  margin: 14px 0px;
  align-items: end;
}

.card-body .title {
  font: normal normal bold 20px/26px Helvetica;
  letter-spacing: 0px;
  color: #212529;
}

.welcome-content .card-body .link-sty a {
  background: #0f4beb;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  border-radius: 4px;
  padding: 4px 10px;
}

.welcome-content .card-body div {
  margin-top: 10px;
}

.link-sty a {
  background: #0f4beb;
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  border-radius: 4px;
  padding: 8px 10px;
}

.welcome-content .card-body div {
  margin-top: 10px;
}

.imp-advice-sty li {
  list-style: none;
  margin-bottom: 2px;
}

.imp-advice-sty li a svg.ext {
  height: 15px;
  width: 15px;
  fill: #727272;
  margin-left: 3px;
  vertical-align: baseline;
}

.sub-list {
  list-style: none;
}

div#toolTip {
  z-index: 9999;
}

.questions-table span.input-group-text {
  margin-left: 6px;
}

.questions-table thead tr th {
  font: normal normal bold 17px/21px Helvetica;
  letter-spacing: 0px;
  color: #212529;
}

.content-widthsidebar.content-wrapper .card-body {
  margin-bottom: 7px;
}

.questions-table td,
.table th {
  border: none !important;
}

/* red heading text */
.redText {
  color: #8b0000;
  font-weight: bold;
  font-family: Helvetica;
}

.redText1 {
  color: #8b0000;
  font: normal normal normal 22px/24px Helvetica;
}

.TextsLast {
  color: #323232;
  font: normal normal normal 16px Helvetica;
  padding-top: 4px;
  line-height: 30px !important;
}

.paragraText {
  color: #8b0000;
  font-size: 20px !important;
  font-family: Helvetica;
  font-weight: bold;
}

.ptext {
  margin-top: 50px;
}

/* .paragraText {
  color: #8b0000;
  font-size: 24px !important;
  font-family: Helvetica;
  font-weight: bold;
}

.ptext {
  margin-top: 50px;
} */

.info img {
  object-fit: cover;
  height: 33px;
  width: 134px;
}

.img-how-to-sty {
  text-align: center;
}

.col-md-12.content-widthsidebar.content-wrapper .card {
  margin-bottom: 10rem;
}

.col-md-12.content-widthsidebar.content-wrapper .card1 {
  margin-bottom: 3rem;
}

.img-how-to-sty {
  text-align: center;
}

.content .wait-for-the .row {
  align-items: center !important;
}

.question-image img {
  border-radius: 4px;
}

table.thinking_table tr.questions {
  /* background-color: #ff2305; */
  background-color: #8b0000;
  font-weight: bold;
  color: #ffffff;
}

.thinking_table {
  padding: 10px;
}

.thinking_table td {
  padding: 15px;
  word-break: break-word;
}

.casualThought-table td {
  border: 1px solid;
}

.casualThought-table .headings {
  /* background-color: #ff2305;  */
  background-color: #8b0000;
}

.casualThought-table .headings td p {
  font-weight: bold;
  color: #ffffff;
}

.thoughts {
  margin-bottom: 15px;
}

table.challenge_table tr.questions {
  /* background-color: #ff2305; */
  background-color: #8b0000;
  font-weight: bold;
  color: #ffffff;
}

.part-two-challenge table.challenge_table {
  margin-bottom: 30px;
  width: 100%;
}

.marginBotoom {
  margin-bottom: 10rem !important;
}

.alignLeft {
  display: flex;
  align-items: flex-start !important;
}

table.challenge_table:hover {
  box-shadow: 0px 0px 20px 0px #ccc;
}

table.challenge_table {
  border-radius: 5px;
  box-shadow: 4px 4px 20px 8px #ccc;
}

table.thinking_table {
  border-radius: 5px;
  width: 90%;
  box-shadow: 4px 4px 20px 8px #ccc;
}

p.selected-txt-sty {
  font-weight: bold;
  border-radius: 4px;
  background: #ecf1fe0f;
  margin-top: 15px;
}

.question-image img {
  width: auto;
  height: 327px;
  object-fit: cover;
}

.left-content row {
  align-items: end;
}

.thinkingEx .row {
  align-items: start !important;
}

.dropdown-wrapper {
  position: relative;
  width: 200px;
}

.dropdown-wrapper .dropdown {
  background: #fff;
  width: 200px;
  height: 100px;
  top: 50px;
  left: 0;
  font-family: "Open Sans";
  font-size: 22px;
  display: flex;
  align-items: center;
}

.ul-wrap ul {
  background: #fff;
  color: #000000;
  list-style: none;
  margin: 0;
  font-size: 18px;
  overflow: hidden;
  height: 0px;
  transition: height 0.3s ease;
  padding: 0;
}

.ul-wrap ul.active {
  height: auto;
  overflow: auto;
}

/* sidebar css  */
h2.accordion-header {
  font-size: 16px;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  background-color: #343a40 !important;
  color: #fff !important;
}

.sidebar-menu-item.active div#panel1a-header {
  background: #007bff;
  padding: 0px 10px;
  align-items: center;
  border-radius: 4px;
  height: 64px;
  margin-bottom: 8px;
}

div#panel1a-header {
  padding: 0px 10px;
  align-items: center;
  border-radius: 4px;
  height: 64px;
  margin-bottom: 8px;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: inherit;
  box-shadow: none !important;
}

p.MuiTypography-root.MuiTypography-body1.css-ahj2mt-MuiTypography-root {
  color: #fff !important;
}
.css-9l3uo3{
  line-height: 1.3 !important;
}

.registerLang{
  position: relative; bottom: 90px; left: 20%;
}
.errorLang{
  position: relative; bottom: 90px; left: 30%;
}

@media(max-width:766px){
  .registerLang{
    position: relative; bottom: 5px; left: 0%;
  }

  .lang{
    font-size: 17px;
  }
  .errorLang{
    position: relative; bottom: 5px; left: 21%;
  }
}

.css-ahj2mt-MuiTypography-root{
  line-height: 1.3 !important;
}

span.headText {
  margin: 0px;
  color: #fff !important;
  height: auto;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  fill: #fff;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  transform: rotate(90deg);
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  transform: rotate(270deg);
}

span.headText {
  white-space: normal;
}

.info svg {
  fill: #fff;
}

div#panel1a-content p {
  white-space: break-spaces;
  margin-bottom: 8px;
}

.info .MuiPaper-root {
  color: #fff;
  box-shadow: none;
  background-color: transparent;
}

.info .MuiAccordionSummary-expandIconWrapper svg {
  transform: rotate(90deg);
}

.info .Mui-expanded svg {
  transform: rotate(180deg);
}

.info span.item-menu-list.active.previous {
  background: #ffffffbd;
  padding: 5px 8px;
  height: 55px;
  color: #1b1818;
  border-radius: 5px;
  display: flex;
  white-space: break-spaces;
  align-items: center;
  margin-bottom: 2px;
}

.info span.item-menu-list.active {
  background: #fff;
  padding: 5px 8px;
  height: 55px;
  color: #1b1818;
  border-radius: 5px;
  display: flex;
  white-space: break-spaces;
  align-items: center;
  margin-bottom: 2px;
}

.questions div {
  margin: 0px;
}

p.questions {
  margin: 0px;
}

ul li {
  margin-bottom: 5px;
}

/* changes */
.user-res-situation {
  font-size: x-large;
  margin: 20px 0;
  text-align: center;
}

.usr-jrny-text {
  text-align: center;
  color: #8b0000;
  font-size: 31px;
}

.textbox textarea {
  background: transparent;
  min-height: 252px;
}

div#panel1a-content p {
  color: #fff;
}

.info {
  padding: 0px !important;
}

.sidebar:hover ::-webkit-scrollbar {
  display: none !important;
}

.user-panel.d-flex.name {
  padding: 10px 10px;
}

table.table.more-safety-behaviours tr td img {
  width: 100px;
  height: auto;
  object-fit: cover;
}

table.table.more-safety-behaviours tr.headings {
  /* background: #ff2305; */
  background: #8b0000;
  color: #fff;
}

table.table.more-safety-behaviours tr td img {
  width: 100px;
  height: auto;
  object-fit: cover;
}

table.table.more-safety-behaviours tr.headings {
  /* background: #ff2305; */
  background: #8b0000;
  color: #fff;
}

div#edit-feared-situation-text {
  background-color: #e3f4fb;
  border: 1px dashed #29aae2;
  border-bottom: 0;
  padding: 10px;
  box-sizing: border-box;
  margin: 0;
}

div#edit-feared-situation {
  background-color: #e3f4fb;
  border: 1px dashed #29aae2;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

div#edit-feared-situation div {
  margin-bottom: 5px;
}

div#edit-feared-situation div input[type="radio"] {
  margin-right: 3px;
}

div#edit-reason-for-avoidance-text {
  margin: 20px 0 0;
  background-color: #ebe4f1;
  border: 1px dashed #673092;
  border-bottom: 0;
  padding: 10px;
}

div#edit-reason-for-avoidance {
  background-color: #ebe4f1;
  border: 1px dashed #673092;
  padding: 10px;
}

div#edit-reason-for-avoidance div {
  margin-bottom: 5px;
}

div#edit-reason-for-avoidance div input[type="radio"] {
  margin-right: 3px;
}

/* css for last page  */
.firstContent {
  background-color: #e3f4fb;
  border: 1px dashed #29aae2;
  width: 40% !important;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: left;
}

.firstContent h1 {
  font-size: 32px;
  color: #29aae2;
  padding: 10px;
  text-align: center;
  border-bottom: 1px dashed #29aae2;
  font-weight: bold;
}

.firstContent p {
  padding: 10px;
}

.secondContent {
  margin-top: 20px;
  background-color: #ebe4f1;
  border: 1px dashed #673092;
  border-radius: 5px;
  box-sizing: border-box;
  text-align: left;
  width: 40% !important;
}

.secondContent h1 {
  font-size: 32px;
  color: #673092;
  padding: 10px;
  text-align: center;
  border-bottom: 1px dashed #673092;
  font-weight: bold;
}

.secondContent p {
  padding: 10px;
}

.thirdContent {
  margin-top: 20px;
  background-color: #ebe4f1;
  border: 1px dashed #673092;
  width: 40% !important;
  border-radius: 5px;
  box-sizing: border-box;
}

.thirdContent h1 {
  font-size: 32px;
  color: #673092;
  padding: 10px;
  margin: 0px;
  font-weight: bold;
}

.rowContent {
  display: flex;
  margin-top: 30px;
  position: relative;
}

.rowContent div {
  height: fit-content;
}

.mainContent {
  margin: 0px 2%;
  text-align: -webkit-center;
}

.firstCol,
.thirdCol {
  background: #ebe4f1;
  border: 1px dashed #673092;
  border-radius: 5px;
  text-align: left;
  margin: 0px 80px;
}

.firstCol h4,
.thirdCol h4 {
  color: #673092;
  padding: 10px;
  text-align: center;
  border-bottom: 1px dashed #673092;
  font-weight: bold;
}

.secondCol {
  background-color: #e3f4fb;
  border: 1px dashed #29aae2;
  border-radius: 5px;
  text-align: left;
}

.secondCol h4 {
  color: #29aae2;
  padding: 10px;
  text-align: center;
  border-bottom: 1px dashed #29aae2;
  font-weight: bold;
}

.thirdCol ul,
.firstCol ul,
.secondCol ul {
  padding: 36px;
}

.checkbx-sty.left-content .input-group-text {
  display: inline-block;
  align-items: center;
  padding: 11px 0px;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: transparent;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: none;
  border-radius: 0.25rem;
}

.left-content.checkbx-sty {
  margin-left: 4.5%;
}

button.loadmoreBtn.btn.btn-primary {
  border: none;
  background: transparent;
  text-decoration: underline;
  color: #0f4beb;
}

.right-img-sty {
  top: 6%;
  position: absolute;
  right: 27%;
}

.right-img-sty-2 {
  top: 26%;
  position: absolute;
  right: 27%;
}

.left-img-sty {
  position: absolute;
  top: 10%;
  left: 23%;
}

.left-img-sty2 {
  top: 42%;
  position: absolute;
  left: 26%;
}

.right-img-sty3 {
  bottom: 51%;
  position: absolute;
  left: 71%;
}

span.mid-img-sty2 {
  position: absolute;
  left: 34%;
  bottom: 92%;
}

span.mid-img-sty {
  bottom: 92%;
  position: absolute;
  right: 34%;
}

span.right-inter img {
  position: relative;
  top: 50%;
  right: 88%;
}

span.left-inter img {
  position: relative;
  left: 88%;
  top: 50%;
}

ul.safety-b-list {
  list-style: none;
  margin-bottom: 5px;
}

ul.safety-b-list li {
  margin-bottom: 7px;
}

table.table.more-safety-behaviours td,
table.table.more-safety-behaviours th {
  border: 1px solid #000;
  margin-bottom: 5px;
}

table.table.more-safety-behaviours {
  margin-bottom: 20px;
}

p.firstSec {
  color: #29aae2;
  font-weight: bold;
  margin: 0;
}

p.newSec {
  color: #0f4beb;
  font-weight: bold;
  margin: 0;
}

p.secondSec {
  color: #673092;
  font-weight: bold;
  margin: 0;
}

.col-md-10.content-wrapper.content-div.btn-show {
  padding-bottom: 320px;
}

table.table.questions-table.predictions td.options {
  width: 9%;
}

.radio-button span.input-group-text {
  border: none;
  margin-bottom: 5px;
  margin-left: 7px;
  background: none;
}

.radio-item-wrap p {
  margin: 0;
}

.radio-item-wrap {
  margin: 10px 0px;
  display: flex;
}

.col-md-8.loader-icon.loader-icon svg {
  height: auto;
}

.experimentHead {
  font-size: x-large !important;
  font-weight: 700;
}

.content-topheads-text .user-res-situation,
.res-user-st {
  font-weight: 700;
  text-align: center;
  font-size: x-large !important;
}

h2.attText a {
  font-size: 24px;
  font-weight: 700;
  color: #0f4beb;
  text-decoration: underline;
}

a.atlink {
  color: #0f4beb;
  text-decoration: underline;
}

h3.questions.sarRes {
  font-weight: 700;
  margin-top: 40px;
}

h2.attText {
  text-align: center;
}

h3.questions.sarRes {
  font-weight: 700;
  text-align: center;
  margin-top: 40px;
}

span.blueText {
  color: #0f4beb;
}

.adminPanel {
  padding-bottom: 90px !important;
}

.asd {
  border: 1px solid #000001;
  background: #0f4beb;
  color: white;
  border-radius: 5px;
  font-weight: 400;
  padding: 5px;
  cursor: pointer;
}

.row.toprow h2 {
  font-weight: bold;
  font-size: 28px !important;
  color: #212529;
}

select.dropDown {
  font-size: 13px;
}

.save-draft-btn {
  float: right;
  margin-right: 8px;
}

.draftButton {
  float: right;
  margin-right: 8px;
}

a.link-enable:hover {
  text-decoration: underline !important;
}

/* cusror pointer not allowed in sidebar */
.link-disable-wrap {
  cursor: not-allowed;
}

/* disable links */
a.link-disable {
  pointer-events: none;
}

span.item-menu-list a {
  font-size: 14px;
}

.col-lg-6.region.region-content {
  height: 80vh !important;
  text-align: center;
  padding-top: 5%;
  box-shadow: 5px 7px 20px 0px #cccc;
  margin: 4% 0px;
  background: #ffffff54;
  border-radius: 8px;
}

.col-lg-6.region.region-content.welcome {
  height: 100vh !important;
  text-align: center;
  padding-top: 5%;
  box-shadow: 5px 7px 20px 0px #cccc;
  margin: 4% 0px;
  background: #ffffff54;
  border-radius: 8px;
}

.dashboardContent
  .error-template
  nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  margin: 0 !important;
}

nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  margin: 0 !important;
}

/*  Responsive css  */
@media screen and (min-device-width: 1440px) and (max-device-width: 1652px) {
  .left-img-sty2 {
    top: 38%;
    left: 26%;
  }
}

@media screen and (max-device-width: 1440px) {
  .question-image img {
    width: auto;
    height: 255px;
    object-fit: cover;
  }
}

@media screen and (min-device-width: 1440px) {
  .col-md-7.ftboxlinks-blue,
  .ftboxlinks-red a {
    font: normal normal bold 14px/28px Helvetica;
  }
}

@media screen and (min-device-width: 1270px) and (max-device-width: 1678px) {
  .left-img-sty {
    top: 8%;
    left: 19%;
  }

  .right-img-sty {
    top: 4%;
    right: 26%;
  }

  .right-img-sty-2 {
    top: 18%;
    right: 26%;
  }

  span.mid-img-sty2 {
    left: 35%;
    bottom: 96%;
  }

  span.mid-img-sty {
    bottom: 96%;
    right: 35%;
  }

  .right-img-sty3 {
    left: 23%;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1528px) {
  .col-lg-6.region.region-content.welcome {
    padding-top: 0 !important;
  }
}

/* responsive css for laptop */
@media only screen and (max-width: 1389px) {
  .lastBar {
    margin: 50px 0px !important;
    height: 50%;
    width: 100%;
  }

  img.checkboxImages {
    width: 80%;
  }

  .left-content .col-lg-10.col-sm-10.col-xs-10 {
    max-width: 75%;
    width: 75% !important;
  }
}

@media only screen and (max-width: 1440px) {
  .ftboxlinks-blue a {
    font-size: 12px !important;
  }

  .ftboxlinks-red a {
    font-size: 12px !important;
  }

  .left-content .col-lg-10.col-sm-10.col-xs-10 {
    max-width: 75%;
    width: 75% !important;
  }
}

@media only screen and (min-width: 1162px) {
  .left-content .col-lg-10.col-sm-10.col-xs-10 {
    max-width: 75%;
    width: 75% !important;
  }
}

@media only screen and (min-width: 1154px) {
  .left-content .col-lg-10.col-sm-10.col-xs-10 {
    max-width: 75%;
    width: 75% !important;
  }
}

@media only screen and (max-width: 991px) {
  .col-lg-6.region.region-content1 {
    padding: 8% !important;
  }
}

span.breakWord {
  word-break: break-all;
}

a.breakWord {
  word-break: break-all;
}

@media only screen and (min-width: 1024.1px) {
 .sidebar {
   display: block !important;
   top:0px;
  }
}

@media only screen and (max-width: 1656px) {
  .col-lg-6.region.region-content1 {
    margin: 3% 0px 12% 0px !important;
  }
}

/* responsive css for laptop: 1216 */
@media only screen and (max-width: 1216px) {
  /* button.user-reponse-submit.save-draft-btn.btn.btn-primary {
        right: 40px;
        top: 85%
    } */

  .ftboxlinks-blue a {
    font-size: 11px !important;
  }

  .ftboxlinks-red a {
    font-size: 11px !important;
  }

  table.thinking_table {
    margin-bottom: 20px;
  }

  .content-topheads-text .row .col-lg-10 {
    max-width: 75% !important;
  }

  .content-topheads-text .row .col-lg-2 {
    max-width: 25% !important;
  }

  audio.react-audio-player {
    width: -webkit-fill-available;
  }

  .img-how-to-sty.centre-align img {
    width: -webkit-fill-available;
  }
}

@media only screen and (max-width: 1216px) and (min-width: 1042px) {
  .ftboxlinks-blue a {
    font-size: 9px !important;
  }

  .ftboxlinks-red a {
    font-size: 9px !important;
  }
}

/* responsive css for mobile */
@media only screen and (max-width: 768px) {
  .passStren {
    position: absolute;
    right: 0;
    font-size: 12px;
  }

  small.text-danger.password-match-instr {
    top: 100%;
    margin-top: 5px;
    position: absolute;
  }

  .pagehead-texttitle {
    margin-top: 10px;
  }

  .content-widthsidebar {
    margin-left: 0px !important;
    padding: 20px;
    max-width: 100%;
  }

  .dashboard-footer {
    left: 0;
    width: 100% !important;
  }

  .dashboard-footer .row.footer {
    padding: 10px 0px;
  }

  .ftboxlinks-red {
    width: 50%;
  }

  .ftboxlinks-blue {
    width: 50%;
  }

  .content-with-sidebar-row .content-wrapper {
    width: 100%;
    max-width: 100%;
  }

  .questions-table tbody tr {
    display: grid;
  }

  .questions-table tbody tr td {
    width: 100%;
  }

  .text-right {
    text-align: left !important;
  }

  .content-with-sidebar-row .content-wrapper {
    padding-bottom: 400px;
  }

  .img-how-to-sty.m-3 img {
    object-fit: contain !important;
    max-width: 480px;
    width: -webkit-fill-available;
  }

  .row.flx-col .col-sm-6 {
    width: 50%;
  }

  .row.flx-col .col-sm-6 img {
    width: -webkit-fill-available;
  }

  .left-content .col-lg-10.col-sm-10.col-xs-10 {
    max-width: 100% !important;
    width: 100% !important;
  }

  .question-image img {
    width: -webkit-fill-available;
  }

  .content-topheads-text .row .col-lg-2 {
    max-width: 100% !important;
    width: 100% !important;
  }

  .col-md-12.content-widthsidebar.content-wrapper {
    padding-bottom: 250px;
  }

  .question-image img {
    height: 300px;
    width: auto;
  }

  table.thinking_table {
    width: 100%;
  }

  .row.content-with-sidebar-row.m-0
    .col-md-10.content-wrapper.content-div
    .content {
    margin-bottom: 18px;
  }

  table.thinking_table {
    margin-bottom: 20px;
  }

  .img-how-to-sty.centre-align img {
    width: -webkit-fill-available;
  }
}

@media screen and (min-device-width: 757px) and (max-device-width: 820px) {
  .content-with-sidebar-row .content-wrapper {
    max-width: 100% !important;
  }

  aside.main-sidebar.sidebar-dark-primary.elevation-4 {
    display: none;
  }

  .dashboard-footer {
    width: 100% !important;
    left: 0px !important;
  }

  .ftboxlinks-blue a {
    font-size: 8px !important;
  }

  table.thinking_table {
    margin-bottom: 20px;
  }

  .ftboxlinks-blue a,
  .ftboxlinks-red a {
    font-size: 8px !important;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1394px) {
  .img-how-to-sty.m-3 img {
    height: auto;
    width: 600px;
  }

  .img-how-to-sty img {
    width: -webkit-fill-available !important;
  }

  .img-how-to-sty img {
    /* width: -webkit-fill-available !important; */
    height: auto;
    object-fit: cover;
  }

  .img-how-to-sty.centre-align img {
    width: auto;
    margin-bottom: 15px;
  }
}

@media screen and (max-device-width: 951px) {
  .img-how-to-sty img {
    width: -webkit-fill-available;
    margin-bottom: 10px;
  }

  .imghowtosty img {
    width: -webkit-fill-available;
  }

  .img-how-to-sty.centre-align img {
    width: -webkit-fill-available;
  }
}

/* testing .css */
.content-wth-bg,
.mainContent {
  height: 100vh;
}

.content-wth-bg {
  overflow: scroll;
}

.first-content-ul,
.second-content-ul {
  width: fit-content;
}

div#lowerRow {
  position: relative;
}

div#firstCol {
  position: absolute;
  left: 11.4%;
  top: 42px;
  background-color: #ebe4f1;
  border: 1px dashed #673092;
  width: 171px;
  min-height: 200px;
}

#firstCol ul,
#secondCol ul,
#thirdCol ul {
  text-align: left;
}

div#secondCol {
  top: 19px;
  left: 37.7%;
  background-color: #e3f4fb;
  border: 1px dashed #29aae2;
  width: 172px;
  min-height: 200px;
  position: absolute;
}

#thirdCol {
  background-color: #ebe4f1;
  border: 1px dashed #673092;
  width: 170.9px;
  min-height: 200px;
  position: absolute;
  left: 64.2%;
  top: 42px;
}

#lowerRow .loader-icon {
  height: min-content;
  width: min-content;
}

div#lowerRow {
  width: 835px;
}

span.item-menu-list.active a.link-enable {
  color: #000000 !important;
}

span.item-menu-list.active a.link-disable {
  color: #323232;
}

span.headText .link-enable {
  color: #fff !important;
}

span.pageList {
  font-size: 12px !important;
}

p.styTxt a.atlink {
  border-bottom: 1px dotted;
}

p.styTxt a.atlink:hover {
  border-bottom-style: solid;
}

.progressBar .progress-wrap {
  display: flex;
}

.progressBar .progress {
  width: 100%;
}

.progress-wrap {
  align-items: center;
}

.progress-wrap p {
  margin-top: 17px;
  margin-right: 13px;
  width: 9%;
}

.progressBar .progress {
  width: 5%;
  margin-right: 20px;
}

.progress-bar.before {
  background: #ff0000;
}

.progress-bar.before {
  background: #ff0000;
}

.progress-bar.after {
  background: #00ff00;
}

/* admin panle css */
.adminPanel table tr {
  padding: 0px 10px;
  border-bottom: 1px solid #00000020;
}

.adminPanel table tr th,
td {
  padding: 8px 0px;
}

.adminPanel {
  height: 100vh;
  overflow: scroll;
  padding: 10px;
  background: #0f4beb14;
}

.row.topBar {
  background: #fff;
  height: 40px !important;
  align-items: center;
  margin: 0px 0px;
  padding: 10px;
  border-radius: 4px;
}

.row.topBar .col-lg-2,
.row.topBar .col-lg-1 {
  text-align: right;
}

.row.topBar .col-lg-1 button {
  background: #0f4beb !important;
  border-radius: 4px !important;
  width: 81px;
  height: 31px !important;
}

.searchIp {
  border: 1px solid #dfdfdf !important;
  height: 40px;
  border-radius: 4px !important;
  width: 100%;
  padding-left: 10px;
}

.adminPanel table {
  width: -webkit-fill-available;
  background: #fff;
}

.checkBox {
  text-align: center;
}

.tableMain {
  border-radius: 4px;
  box-shadow: 3px 3px 10px 3px #ccc;
}

.pagination {
  align-items: center;
}

.pagination button {
  border: 1px solid;
  border-color: transparent !important;
}

.pagination span {
  color: #0f4beb;
  font-size: 13px !important;
}

.pagination select {
  border: 1px solid #ccc;
  height: 31px;
  background: transparent;
}

.pagination input {
  border: 1px solid #ccc;
  height: 31px;
  margin: 2px;
}

/* .row.toprow {margin: 0px 35px;} */
.adminPanledash nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
  margin: 0px 10px;
}

.fade.modelNew.modal.show .modal-dialog {
  top: 33%;
}

.abc{
  width: 250px !important;
}

button.btn-close::before {
  content: "\f00d";
}

button.btn.btn-primary {
  background-color: #0f4beb;
}

button.btn-close {
  background: none;
  border: none;
}

@media screen and (max-device-width: 392px) {
  /* button.user-reponse-submit.save-draft-btn.btn.btn-primary {
        top: calc(100% - 200px) !important;
    } */
}

@media screen and (max-device-width: 500px) {
  /* button.user-reponse-submit.save-draft-btn.btn.btn-primary {
        right: 15px !important;
        top: 81% !important;
    } */
  img.weclomePng {
    /* background: red; */
    height: auto !important;
  }

  .dashboardContent.error-template .col-lg-3 {
    display: none;
  }

  .regbtn-block .btn.prevbtn {
    width: 150px;
  }

  button.btn.nextbtn.btn-block.btn-primary.btn-sm {
    width: 126px;
  }

  .row.topBar {
    display: flex;
    height: 76px !important;
  }

  .oterDiv {
    width: 1200px;
  }

  .row.topBar .col-lg-5 {
    display: none;
  }

  .row.topBar .col-lg-2,
  .row.topBar .col-lg-4,
  .row.topBar .col-lg-1 {
    width: 33%;
  }

  input.searchIp {
    float: left;
  }

  .dashboardContent.error-template img {
    width: 60%;
  }

  .dashboardContent.error-template .col-lg-6.region.region-content {
    margin: 25px;
    /* height: auto !important; */
  }

  .dashboardContent.error-template .col-lg-6.region.region-content.welcome {
    margin: 25px;
    /* height: auto !important; */
  }
}

/* end */

@media screen and (max-device-width: 1230px) {
  .row.topBar {
    display: flex;
    height: 76px !important;
  }

  .img-how-to-sty img {
    width: -webkit-fill-available !important;
  }

  .oterDiv {
    width: 1200px;
  }

  .row.topBar .col-lg-5 {
    display: none;
  }

  .row.topBar .col-lg-2,
  .row.topBar .col-lg-4,
  .row.topBar .col-lg-1 {
    width: 33%;
  }

  .row.topBar .col-lg-1 button {
    float: left;
  }
}

.link-disable-wrap,
.link-enable-wrap {
  white-space: break-spaces;
}

.imghowtosty {
  margin: 26px !important;
  text-align: center;
}

table.challenge_table tr td {
  padding: 10px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef87 !important;
}

@media screen and (max-device-width: 820px) {
  body.sidebar-mini.sidebar-open
    aside.main-sidebar.sidebar-dark-primary.elevation-4 {
    display: block !important;
    top: 57px;
  }
}

/* model page  */
.first-content-ul.pt-3,
.second-content-ul.pt-3 {
  /*margin-right: 22px;*/
  width: 401px;
  margin-top: 5px;
}

.second-content-ul.pt-3 ul.pt-3 li p,
.first-content-ul.pt-3 ul.pt-3 li p {
  text-align: left;
}

@-moz-document url-prefix() {
  .first-content-ul.pt-3 {
    position: absolute;
    right: 32%;
    width: 457px;
  }

  .mainContent {
    position: relative;
  }

  .second-content-ul.pt-3 {
    position: absolute;
    left: 31.3%;
    width: 454px !important;
  }

  .second-content-ul.pt-3 {
    position: absolute;
    left: 31.3%;
    width: 454px !important;
    top: 65%;
  }

  .afterDiv {
    position: relative;
  }

  div#firstCol {
    position: absolute;
    left: 34.4%;
    top: 250px;
    background-color: #ebe4f1;
    border: 1px dashed #673092;
    width: 173px;
    min-height: 200px;
  }

  div#secondCol {
    position: absolute;
    top: 227px;
    left: 60.7%;
    background-color: #e3f4fb;
    border: 1px dashed #29aae2;
    width: 172px;
    min-height: 200px;
  }

  #thirdCol {
    background-color: #ebe4f1;
    border: 1px dashed #673092;
    width: 172.9px;
    min-height: 200px;
    position: absolute;
    left: 86.9%;
    top: 250px;
  }
}

.content-wrap.whatLearned {
  margin-bottom: 30px;
}

.row.ipForms .textbox {
  width: 90%;
}

/* complete page chart */
.lastBar {
  width: 80%;
  padding: 2%;
  align-items: center;
}

/* .alignCenter {align-items: center;} */
.marginBotoom.mt-3.card.mb-4 .alignCenter.p-3 .lastBar {
  width: 100%;
  padding: 0px 7%;
}

.marginBotoom.mt-3.card.mb-4 {
  margin-bottom: 10rem !important;
}

.dashboardContent.error-template .row {
  background: #0f4beb14;
  height: 100vh;
  overflow: scroll;
}

img.weclomePng {
  height: 300px;
  object-fit: contain;
}

.content.welcomeContent {
  padding: 10px 19%;
  text-align: justify;
}

button.user-reponse-submit.save-draft-btn.btn.btn-primary {
  /* position: fixed !important; */
  /* right: 3%; */
  /* top: calc(100% - 240px); */
  /* z-index: 99999; */
  width: 83px;
  /* margin-right: 29px; */
}

.draftButton {
  width: 83px !important;
  margin: 14px 1px 0px 9px !important;
  padding: 4px;
}

.row.footer .ft-headstext {
  text-align: center;
}

/* .row.footer .audio-title,
.ft-headstext.needHelp {
    visibility: hidden;
} */

.dashboardContent.error-template img {
  object-fit: scale-down;
  width: 40%;
}

.content.welcomeContent1 {
  text-align: justify;
}

.col-lg-6.region.region-content1 {
  text-align: left;
  padding-top: 5%;
  box-shadow: 5px 7px 20px 0px #cccc;
  margin: 3% 0px 9% 0px;
  background: #ffffff54;
  border-radius: 8px;
  padding: 1%;
  font-size: "30px";
}

#heading {
  font-size: 30px !important;
}

#para {
  color: #8b0000;
  font-size: 24px;
}

#StaticFooter p:hover {
  color: blue;
}

.fixedDiv {
  position: fixed !important;
  top: 92%;
  background: #f1f2f2;
  padding: 2%;
}

.next {
  display: flex;
  justify-content: flex-end;
  padding-right: 13px;
}

/* for audio clue hidden */

.audio-title {
  visibility: hidden;
}

.col-md-4.row.footer .ft .needHelp {
  margin-bottom: 8px;
  text-align: left !important;
}

.text {
  font-size: 16px !important;
}

/* part4 new image style */

.sean_edited_image {
  width: 250px !important;
  height: auto;
}

.equation_edited_image {
  width: 530px !important;
  height: "auto";
}

/*part5 new image style*/

.part5_shop2_image {
  width: 550px;
  height: auto;
}

/*part6 new image style */

.part6_bar_image {
  width: 550px;
  height: auto;
}

.part6_imagery2_image {
  width: 550px;
}

/* part 3 new image style */

.part3_intro_image {
  width: 660px;
  height: auto;
}

.part3_benmodel_image {
  width: 600px;
  height: auto;
}

.part3_j2_image {
  width: 250px;
  height: auto;
}

.part3_j3_image {
  width: 400px;
  height: auto;
}

.part3_susan1_image {
  width: 200px;
  height: auto;
}

.part2_susan3_image {
  /* width: 320px; */
  /* width: 350px; */
  width: 435px;
  height: auto;
}

.part3_more_safety_images {
  height: 142px;
}

/* part1 new images style */

.part1_everyone_image {
  width: 330px;
  height: auto;
}

.part1_waiting_other_images {
  width: 280px;
  height: auto;
}

.part1_park_image {
  width: 750px;
  height: auto;
}

.topQuestion,
.help-text {
  font-size: 20px !important;
}

.subtitle-text,
.questions.sarRes {
  font-size: 24px !important;
}

/* .second-content-ul.pt-3{
    padding: 0 !important;
} */

.second-content-ul {
  margin: 0 !important;
}

.second-content-ul ul {
  padding-top: 0.75rem !important;
}

/* part 3 page 13 */

@-moz-document url-prefix() {
  div#firstCol {
    position: absolute;
    left: 11.3%;
    top: 250px;
    background-color: #ebe4f1;
    border: 1px dashed #673092;
    width: 173px;
    min-height: 200px;
  }

  div#secondCol {
    position: absolute;
    top: 227px;
    left: 37.7%;
    background-color: #e3f4fb;
    border: 1px dashed #29aae2;
    width: 172px;
    min-height: 200px;
  }

  #thirdCol {
    background-color: #ebe4f1;
    border: 1px dashed #673092;
    width: 172.9px;
    min-height: 200px;
    position: absolute;
    left: 63.9%;
    top: 250px;
  }
}

/* @media (min-width: 821px) {
  .content-with-sidebar-row .content-wrapper {
    margin-left: 250px !important;
  }
} */
/* @media (max-width: 820px) {
  .content-with-sidebar-row .content-wrapper {
    width: 100% !important;
  } */

/* #mainContainer > div:nth-child(2).row {
    display: block;
  }
}*/

@media (max-width: 600px) {
  .ipForms {
    flex-flow: column-reverse;
    align-items: start !important;
  }
}

/* @media only screen and (max-width: 1024.1px) and (min-width: 768px) {
  .row .content-widthsidebar.content-wrapper{
    width:100% !important ;
  }

  .row .position-static{
    width: 0% !important;
  }

} */

@media (max-width: 1024px) {
  .main-sidebar {
    top: 57px !important;
  }

  body.sidebar-mini.sidebar-closed.sidebar-collapse
    #mainContainer
    > div:nth-child(2).row,
  body.sidebar-mini.sidebar-open #mainContainer > div:nth-child(2).row {
    display: block;
  }

  body.sidebar-mini.sidebar-closed.sidebar-collapse
    .content-with-sidebar-row
    .content-wrapper,
  body.sidebar-mini.sidebar-open .content-with-sidebar-row .content-wrapper,
  .content-widthsidebar {
    max-width: 100%;
    width: 100%;
  }

  .content-with-sidebar-row .content-wrapper,
  .content-widthsidebar {
    margin-left: 0 !important;
  }

  .footer div:last-child .row a {
    display: block;
    height: 100%;
  }

  .dashboard-footer {
    left: 0 !important;
    width: 100% !important;
  }
}
