/* Custom Styling*/
/* @font-face {
    font-family: "Helvetica";
    src: local("Helvetica"),
     url("./fonts/Helvetica.ttf") format("truetype");
    font-weight: bold;
    } */

body,
html {
    font-family: "Helvetica" !important;
    height: 100vh;

}

.login-container-wrap {
    height: 100vh;
}

.login-col-wrap {
    width: 100%;
}

.login-submit,
.login-sign-up {
    margin-top: 40px;
}

.login-submit{
    display: flex;
    justify-content: center;
    column-gap: 10px;
}

.d-flex.align-items-center.login-container-wrap>div {
    width: 100%;
}

.regbtn-block {
    padding: 0px 1.25rem;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: center;
}

.progress-barblock .progress {
    max-width: 100px;
    margin-left: auto;
    margin-right: auto;
}

.labelSection {
    display: flex;
    justify-content: space-between;
}

.passStren {
    display: flex;
    align-items: center;
    font: normal normal normal 14px/21px Helvetica;
    letter-spacing: 0px;
    color: #7D7D7D;
}

.passStren .progress {
    width: 100px;
    margin-left: 10px;
}

.forminut-passeye,
.form-group.forminut-confirmPassShow {
    position: relative;
}

.passShow-eye,
.confirmPassShow-eye {
    position: absolute;
    right: 10px;
    top: 37px;
}

.regbtn-block .prevbtn,
.regbtn-block .prevbtn+button.nextbtn {
    max-width: 150px;
    margin: 0px !important;
}

.login-col-wrap>.col-md-6 .text-center>img {
    max-width: 100%;
}

.cofirmlabel input {
    margin-right: 10px;
}

.confirmpass-label-ins {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.password-match-instr {
    font: normal normal normal 14px/21px Helvetica;
    letter-spacing: 0px;
    color: #7D7D7D;
}

/*--- login ---*/

.pagehead-texttitle {
    font: normal normal bold 28px/34px Helvetica;
    letter-spacing: 0px;
    color: #212529;
}

.login-container-wrap {
    font: normal normal normal 17px/21px Helvetica;
    letter-spacing: 0px;
    color: #323232;
}

.login-container-wrap a {
    font: normal normal normal 16px/21px Helvetica;
    letter-spacing: 0px;
    color: #0F4BEB;
}

.btn.blue-style-btns,
.regbtn-block .btn.nextbtn {
    background: #0F4BEB;
    border-radius: 3px;
    border-color: #0F4BEB;
    font: normal normal bold 15px/28px Helvetica;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.regbtn-block .btn.prevbtn:hover,
.btn.blue-style-btns:hover,
.regbtn-block .btn.nextbtn:hover {
    opacity: 0.8;
}

.regbtn-block .btn.prevbtn {
    background: #F8F9FA;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    font: normal normal bold 15px/28px Helvetica;
    letter-spacing: 0px;
    color: #444444;
}

p {
    font: normal normal normal 17px/21px Helvetica;
    letter-spacing: 0px;
    color: #000000;
}

.progress-bar {
    border-radius: 5px;
}

.usersituation-body p {
    font: normal normal normal 17px/21px Helvetica;
    letter-spacing: 0px;
    color: #7D7D7D;
}

.cofirmlabel label {
    font: normal normal normal 17px/21px Helvetica;
    letter-spacing: 0px;
    color: #7D7D7D;
}

.form-group.login-sign-up.text-center.signuptext.align-item-top.d-flex.flex-row.justify-content-center {
    padding: 0px 27px !important;
}